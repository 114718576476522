import { useEffect } from "react";
import { PageSeo } from "utilities/seo/PageSeo";
import { Chip } from "../../components/Chip/Chip";
import { PageLayout } from "../../components/Layout/PageLayout";
import { CustomLink } from "../../components/Links/CustomLink";
import { Title } from "../../components/Navbar/Title";
import { routes } from "../../routes";
import { setUserVersion } from "../../services/version";

// Create dedicated label modal to have uniform labels
const labels = {
  NEW: <Chip color="red">New</Chip>,
};

// Add new patch notes here.
export const patchnotes: PatchNote[] = [
  {
    version: "2.0.18",
    date: new Date(2025, 1, 24),
    updates: [
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/worlds-islands-larger-or-smaller">
              World's Islands: Larger or Smaller?
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
    ],
  },
  {
    version: "2.0.17",
    date: new Date(2024, 11, 2),
    updates: [
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/christmas-spending">
              Christmas Spending
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: "Fixed data in country game modes",
      },
    ],
  },
  {
    version: "2.0.16",
    date: new Date(2024, 10, 19),
    updates: [
      {
        name: "Updated data for Population by Country",
      },
    ],
  },
  {
    version: "2.0.15",
    date: new Date(2024, 6, 5),
    updates: [
      {
        name: "Updated data for YouTube Game modes",
      },
      {
        name: "Updated data for Spotify Game modes",
      },
    ],
  },
  {
    version: "2.0.14",
    date: new Date(2024, 6, 4),
    updates: [
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/tallest-buildings">
              Which Building Is Taller?
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
    ],
  },
  {
    version: "2.0.13",
    date: new Date(2024, 4, 12),
    updates: [
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/mrbeast-youtube-video-views">
              MrBeast Video Views
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/esc-2024-points">
              ESC 2024 - Which country got more points?
            </CustomLink>
            {""}
            Congratulations to Switzerland!
          </>
        ),
        label: labels.NEW,
      },
      {
        name: "Updated data for YouTube Game modes",
      },
    ],
  },
  {
    version: "2.0.12",
    updates: [
      {
        name: (
          <>
            <a
              href="https://www.reddit.com/r/HistoryMemes/comments/1b3qmom/i_created_a_game_where_you_have_to_guess_which/"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              💙 Thanks for everyone who contributed to historical events game
              mode!
            </a>
          </>
        ),
      },
      {
        name: (
          <>
            Added 30 new historical events to{" "}
            <CustomLink to="/game/historical-happenings">
              Historical Happenings
            </CustomLink>{" "}
            leading to a new total of 66 events.
          </>
        ),
      },
      {
        name: "Improved naming for events in Historical Happenings. Thanks to PresidentSkillz for the report.",
      },
      {
        name: "Fixed wrong order or overlapping order for events in Historical Happenings. Thanks to GordonHead87 and Arthur-Wales for the report.",
      },
    ],
    date: new Date(2024, 3, 2),
  },
  {
    version: "2.0.11",
    updates: [
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/historical-happenings">
              Historical Happenings
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: "Fixed a bug in country games where flags were not displayed correctly. Thanks to Magic SvenF for the report.",
      },
      {
        name: "Fixed overlapping layouts on mobile devices. Thanks to axell9942 for the report.",
      },
    ],
    date: new Date(2024, 2, 28),
  },
  {
    version: "2.0.10",
    updates: [
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/esc-2023-points">
              ESC 2023 - Which country got more points?
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/number-of-stores">
              Number of Stores
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: "Updated data for all Spotify & YouTube Game modes",
      },
      {
        name: "Updated UI and fixed minor bugs",
      },
      {
        name: "Restructured internal structures",
      },
    ],
    date: new Date(2023, 4, 14),
  },
  {
    version: "2.0.9",
    updates: [
      {
        name: "Fixed population data for Montenegro. Thanks to Urufusan, Darko, Martin, dre Baly and Dusan!",
      },
      {
        name: "Fixed population data for Belize. Thanks to Oscar and dre Baly!",
      },
      {
        name: "Fixed country area size data for Uzbekistan. Thanks to ori!",
      },
      {
        name: "Fixed wrong capital of Turkey. Thanks to Ezgi!",
      },
      {
        name: "Fixed bug in movie inflation game mode. Thanks to Gavin!",
      },
    ],
    date: new Date(2023, 2, 21),
  },
  {
    version: "2.0.8",
    updates: [
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/weights">What weighs more?</CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/weights-small-items">
              What weighs more? Small Items
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: "Reorder tabs",
      },
      {
        name: "Add new tab 'weights'",
      },
    ],
    date: new Date(2023, 1, 26),
  },
  {
    version: "2.0.7",
    updates: [
      {
        name: "Improved titles and description on games overview page",
      },
    ],
    date: new Date(2023, 1, 23),
  },
  {
    version: "2.0.6",
    updates: [
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/football-players-market-value-global">
              WM22 Players Market Values
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: "Fixed 'Invalid Date' bug in SpiderMonkey Engine (Firefox). Thanks to Apteryx.",
      },
      {
        name: "Improve random string algorithm to slowly reveal string",
      },
    ],
    date: new Date(2022, 11, 17),
  },
  {
    version: "2.0.5",
    headerImage: "/img/patchnotes/patch_205_image.png",
    updates: [
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/spotify-artists-follower-count">
              Spotify Artists Follower Count
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/spotify-top-tracks">
              Spotify Top Tracks
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: (
          <>
            Updated data for{" "}
            <CustomLink to="/game/spotify-monthly-listeners">
              Spotify Monthly Listeners
            </CustomLink>
          </>
        ),
      },
    ],
    date: new Date(2022, 10, 10),
  },
  {
    version: "2.0.4",
    updates: [
      {
        name: "Fix wrong data with YouTubers! Thanks to Marco Settembre and Bnanik!",
      },
    ],
    date: new Date(2022, 7, 24),
  },
  {
    version: "2.0.3",
    updates: [
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/youtuber-view-count">
              YouTube Views
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: (
          <>
            New YouTubers added to{" "}
            <CustomLink to="/game/youtubers">YouTubes</CustomLink>
          </>
        ),
      },
      {
        name: (
          <>
            Updated data for{" "}
            <CustomLink to="/game/fortnite-youtubers">
              Fornite Youtubers
            </CustomLink>
          </>
        ),
      },
    ],
    date: new Date(2022, 7, 19),
  },
  {
    version: "2.0.2",
    updates: [
      {
        name: "Update games overview to have links for categories.",
      },
    ],
    date: new Date(2022, 7, 7),
  },
  {
    version: "2.0.1",
    updates: [
      {
        name: "Fix bug where you could not use the back button when you came from a outside link",
      },
      {
        name: "Fix bug with UI Flickering while loading a game",
      },
      {
        name: "Fix bug where vs icon is not centered correclty",
      },
    ],
    date: new Date(2022, 7, 1),
  },
  {
    version: "2.0.0",
    headerImage: "/img/banner_v2.png",
    description:
      "Thank you for more than 800k users this year! This final gave us the motivation to start working on a new version of moreorless. We rebuild everything again from scratch and are now able to easily work on new game modes, features and a lot more! Have fun guessing :D",
    updates: [
      {
        name: "Layout/Design for Games Overview, Profile, Settings, Game End screen, Pages… and a lot small details",
        label: labels.NEW,
      },
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/population-by-country">
              Population by Country
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/country-population-density">
              Country Population Density
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/csgo-weapon-prices">
              CS:GO Weapon Prices
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: (
          <>
            Game Mode{" "}
            <CustomLink to="/game/most-visited-websites">
              Most Visited Websites
            </CustomLink>
          </>
        ),
        label: labels.NEW,
      },
      {
        name: "Updated data for most other Game modes (some will update later this week)",
      },
      {
        name: "Improved game logic and increased performance for animations",
      },
      {
        name: "A lot of changes in the background behind the scenes to make the best game experience you ever had!",
      },
    ],
    date: new Date(2022, 5, 1),
  },
];

type PatchNote = {
  version: string;
  headerImage?: string;
  description?: string;
  updates: {
    name: JSX.Element | string;
    label?: JSX.Element;
  }[];
  date: Date;
};

/**
 * Patch note page. Dont add any patch notes here only in the array above.
 */
export const PatchNotes: React.FC = () => {
  useEffect(() => {
    setUserVersion();
  }, []);

  return (
    <PageLayout>
      <PageSeo
        title="Patch Notes"
        description="Stay up-to-date with the latest updates and improvements to the More or less game on our Patch Notes page. Here, you can view a detailed list of all the changes and bug fixes that have been made to the game, ensuring the best possible gaming experience for our players. Thank you for your support and for playing moreorless!"
        canonicalLink={routes.misc.patchnotes}
      />
      <Title>Patch Notes</Title>
      {patchnotes.map((patchnote) => (
        <div key={patchnote.version} className="mb-6 md:mb-8">
          <div
            style={
              patchnote.headerImage
                ? { backgroundImage: `url('${patchnote.headerImage}')` }
                : {}
            }
            className={`flex justify-between items-center ${
              patchnote.headerImage
                ? `bg-no-repeat bg-cover bg-center bg-black bg-opacity-20 bg-blend-darken pt-20 px-3 md:px-5 pb-2 md:pb-4 mb-3`
                : ""
            } mb-1`}
          >
            <h2 className="text-lg md:text-2xl font-semibold">
              Version {patchnote.version}
            </h2>
            <p className="text-gray text-sm md:text-base">
              {patchnote.date.toLocaleDateString("en-GB", {
                month: "long",
                year: "numeric",
              })}
            </p>
          </div>
          <div className="mb-4 md:mb-5">
            {patchnote.description && (
              <p className="text-gray text-sm md:text-base">
                {patchnote.description}
              </p>
            )}
          </div>
          <ul>
            {patchnote.updates.map((update, index) => (
              <li key={index} className="mb-2 md:mb-3">
                {update.label && <span className="mr-2">{update.label}</span>}
                <span className="text-sm md:text-base">{update.name}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </PageLayout>
  );
};
